import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BusinessImg from '@/assets/img/advocacy-for-children/business_img@2x.png';
import KvImg from '@/assets/img/advocacy-for-children/business_kv.jpg';
import KvImgM from '@/assets/img/advocacy-for-children/business_kvM@2x.png';
import RuleImg from '@/assets/img/advocacy-for-children/business_rule@2x.png';
import WhatImg from '@/assets/img/advocacy-for-children/business_what@2x.png';
import ButtonMoreView from '@/components/ButtonMoreView';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PublicPrivateTab } from '@/page-blocks/advocacy-for-children/public-private-partnership';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리사업팀`,
    tel: `02-724-8586`,
    email: `crp@unicef.or.kr`,
  },
];

const Rule = styled.dl`
  display: flex;
  margin-bottom: 16px;
  line-height: 2;
  font-weight: 700;

  dt {
    color: #1cabe2;
  }
  .tip {
    margin-top: 32px;
    font-size: 14px;
    line-height: 2;
    ${breakpoint(640)} {
      font-size: 12px;
    }
  }
  ${breakpoint(`tablet`)} {
  }
`;

const SectionTop = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 0;
    position: relative;
    z-index: 1;
  }
  .kv-container {
    margin-top: -50px;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 192px;
      height: 192px;
      display: block;
      position: absolute;
      bottom: 0;
      border-radius: 0 100% 0 0;
    }
    &:before {
      right: 100%;
      background: #b2ddf1;
    }
    &:after {
      left: 100%;
      background: #1caae2;
    }
  }
  ${breakpoint(1160)} {
    .kv-container {
      margin-top: -4.31vw;

      &:before,
      &:after {
        width: 16.55vw;
        height: 16.55vw;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .kv-container {
      margin-top: 48px;

      &:before,
      &:after {
        display: none;
      }
    }
  }
`;
const SectionWhat = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }

  .dec {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  figure {
    margin-top: 70px;
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 48px;
    }
    figure {
      margin-top: 65px;
    }
  }
`;
const SectionPromise = styled(Section)`
  .blit-list {
    & > li {
      margin-bottom: 16px;
      line-height: 2;
      strong {
        color: #1cabe2;
      }
    }
  }
  ${breakpoint(`tablet`)} {
  }
`;
const SectionRule = styled(Section)`
  .flex-wrap {
    align-items: flex-start;
    justify-content: space-between;
    .col-img {
      width: 50%;
    }
    .col-dec {
      width: 50%;
      max-width: 512px;
    }
  }
  ${breakpoint(`tablet`)} {
    .flex-wrap {
      .col-img,
      .col-dec {
        width: 100%;
        max-width: 100%;
      }
      .col-dec {
        margin-top: 33px;
      }
    }
  }
`;

const SectionBusiness = styled(Section)`
  .flex-wrap {
    align-items: flex-start;
    justify-content: space-between;
    .col-img {
      width: 54%;
    }
    .col-dec {
      width: 46%;
      max-width: 504px;
      padding-top: 57px;

      .blit-list {
        & > li {
          margin-bottom: 32px;
          line-height: 2;

          strong {
            color: #1cabe2;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    .flex-wrap {
      .col-img,
      .col-dec {
        width: 100%;
        max-width: 100%;
      }
      .col-dec {
        padding-top: 0;
      }
      .col-img {
        margin-top: 48px;
      }
    }
  }
`;
const SectionWhy = styled(Section)`
  .blit-list {
    margin-top: 64px;
    & > li {
      margin-bottom: 16px;
      line-height: 2;
    }
  }
  .btn-wrap {
    text-align: right;
    margin-top: 96px;

    ${ButtonMoreView} {
      width: 100%;
      max-width: 352px;
      text-align: left;
    }
  }
  ${breakpoint(`tablet`)} {
    .blit-list {
      margin-top: 48px;
    }

    .btn-wrap {
      margin-top: 48px;

      ${ButtonMoreView} {
        max-width: 100%;
      }
    }
  }
`;

const ChildrensRightsAndBusiness: React.FC<PageProps> = ({ location }) => {
  const [news, setNews] = useState<CampaignData[]>([]);

  const loadNews = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A028'],
      });
      const articles = data as any;
      setNews(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  return (
    <LayoutWithTitle
      // 강제로 breadcrumb 고정
      location={{
        ...location,
        pathname: `/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-cities`,
      }}
      paddingBtt
      title="아동친화사회 만들기"
      description="for every child, business principles"
    >
      <PublicPrivateTab currentPath={location.pathname} />
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  어린이와 더불어 성장하는 기업 만들기, <br />
                  유니세프 아동친화기업
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>
          <figure className="kv-container">
            <Image pcSrc={KvImg} mobileSrc={KvImgM} />
          </figure>
        </Container>
      </SectionTop>

      <SectionWhat className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc t2">
            <h2>
              <Tit size="s1">아동친화기업은 무엇인가요?</Tit>
            </h2>
            <p className="header-dec">
              <Tit size="s4" color="sky">
                유니세프 아동친화기업(CRB: Children’s Rights and Business, 이하
                아동친화기업)은 아동권리를 포함한 인권과 기업 관련 국제규범을
                바탕으로 정부, 기업 및 아동권리와 연관성을 갖는 다양한
                이해관계자들이 아동권리와 경영원칙을 실제로 이행할 수 있도록
                하는 유니세프의 글로벌 활동입니다.
              </Tit>
            </p>
          </SectionHeader>

          <div className="dec-wrap">
            <p className="dec">
              세계화 흐름 속 기업의 활동은 전 세계 인구의 3분의 1을 차지하는
              어린이에게 직간접적으로 큰 영향을 주고 있으며, 기업의 아동권리
              침해 형태도 점차 다양해지고 있습니다. 기업은 어린이 노동 철폐뿐
              아니라 다양한 분야에서 아동권리를 존중하고 지원할 방안을 마련해야
              합니다. 무엇보다 기업은 어린이의 취약성을 이해하고 보호해야하는
              동시에, 어린이 스스로가 권리의 주체이자 기업에 영향력을 행사할 수
              있는 주요 이해관계자임을 인식해야 합니다.
            </p>
            <p className="dec">
              2011년 ‘유엔 기업과 인권 이행원칙’ 발표는 ‘국가의 인권 보호 의무’
              그리고 ‘기업의 인권 존중 책임’을 모두 강조하였으며, 이를 토대로
              2012년 유니세프, 유엔글로벌콤팩트 및 세이브더칠드런은 ‘아동권리와
              경영원칙(Children’s Rights and Business Principles, CRBP)’을 공동
              발표하였습니다. 유니세프는 아동친화기업이라는 글로벌 활동을 통해
              정부의 아동권리 보호 의무 그리고 기업의 아동권리 존중 책임의
              중요성을 강조합니다.
            </p>
          </div>
          <figure>
            <Image pcSrc={WhatImg} mobileSrc={WhatImg} />
          </figure>
        </Container>
      </SectionWhat>

      <SectionPromise className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc t2">
            <h2>
              <Tit size="s1">기업의 존중 책임 및 지원 약속</Tit>
            </h2>
            <p className="header-dec">
              아동친화기업은 기업이 아동권리에 대한 기업의 존중 책임 및 지원
              약속을 토대로 아동권리를 기업 경영 전반에 적용하는 것을 목표로
              합니다.
            </p>
          </SectionHeader>
          <ul className="blit-list">
            <li>
              <strong>아동권리 존중 책임: </strong>기업은 아동권리를 포함한 모든
              인권 침해를 예방하고, 인권에 부정적 영향을 미치는 경영활동에
              대응하도록 합니다. 기업의 존중 책임은 기업의 경영활동, 제품과
              서비스, 종업원, 공급업체, 고객, 사업 파트너를 비롯한 모든 사업
              관계에서 발생하는 모든 직/간접적 영향의 범위에 적용됩니다.
            </li>
            <li>
              <strong>아동권리 지원 약속: </strong>기업이 인권을 존중하는 것뿐만
              아니라, 더 나아가 아동권리를 포함한 인권이 개선되도록 자발적
              활동을 개진합니다. 이러한 활동에는 기업의 주요 경영활동에서
              비롯되는 제품과 서비스뿐 아니라 전략적 사회투자, 자선활동, 캠페인,
              공공정책 참여활동, 여러 파트너와 협력 등이 있습니다.
            </li>
          </ul>
        </Container>
      </SectionPromise>

      <SectionRule className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">아동권리와 경영원칙</Tit>
            </h2>
          </SectionHeader>
          <div className="flex-wrap flex">
            <div className="col-img">
              <Image pcSrc={RuleImg} mobileSrc={RuleImg} />
            </div>
            <div className="col-dec">
              <Rule>
                <dt>원칙1. </dt>
                <dd>
                  아동권리 존중의 책임을 다하며, 아동권리를 지원할 것을 약속해야
                  합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙2. </dt>
                <dd>
                  모든 기업 활동과 사업 관계에서 아동노동 철폐에 기여해야
                  합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙3. </dt>
                <dd>
                  연소근로자와 부모, 양육자에게 양질의 일자리를 제공해야 합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙4. </dt>
                <dd>
                  모든 기업 활동 및 시설에서 아동을 보호하고 안전을 보장해야
                  합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙5. </dt>
                <dd>
                  제품과 서비스의 안전함을 보장하고, 이를 통해 아동권리를
                  지원해야 합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙6. </dt>
                <dd>
                  아동권리를 존중하고 지원하는 마케팅과 광고를 해야 합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙7. </dt>
                <dd>
                  토지의 취득, 사용 및 환경과 관련해 아동의 권리를 존중하고
                  지원해야 합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙8. </dt>
                <dd>
                  안전 보장 조치에서 아동의 권리를 존중하고 지원해야 합니다.
                </dd>
              </Rule>
              <Rule>
                <dt>원칙9. </dt>
                <dd>긴급 상황의 영향을 받는 아동의 보호를 도와야 합니다.</dd>
              </Rule>
              <Rule>
                <dt>원칙10. </dt>
                <dd>
                  아동권리를 보호하고 실현하기 위한 지역사회와 정부의 노력에
                  동참해야 합니다.
                </dd>
              </Rule>
              <p className="tip">
                * 원칙1이 아동친화기업 경영을 목표하는 모든 기업에 적용되는
                원칙인 것에 비하여, 원칙2부터 10은 일터, 시장 그리고 지역사회 및
                환경 분야에서 거론될 수 있는 특정주제(아동노동, 광고와 마케팅,
                긴급상황 등)에 적용됩니다.
              </p>
            </div>
          </div>
        </Container>
      </SectionRule>

      <SectionBusiness className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc t2">
            <h2>
              <Tit size="s1">아동친화기업 경영하기</Tit>
            </h2>
          </SectionHeader>

          <div className="flex-wrap flex">
            <div className="col-dec">
              <ul className="blit-list">
                <li>
                  <strong>아동친화기업 정책선언 : </strong>아동권리에 기반한
                  경영방침을 수립하고 선언합니다.
                </li>
                <li>
                  <strong>아동권리영향 평가 : </strong>아동권리에 미칠 수 있는
                  위험 및 기회요인을 평가하여 활동 계획 수립을 촉구합니다.{` `}
                </li>
                <li>
                  <strong>아동권리 관점에서의 의사결정 : </strong>아동권리 영향
                  평가를 바탕으로 수립한 활동 계획을 이행함으로써 기업의
                  의사결정에 아동권리를 반영합니다.
                </li>
                <li>
                  <strong>아동권리 기반 성과측정 및 커뮤니케이션 : </strong>
                  지속가능경영 보고서 제출 등 정기적인 성과 측정 및 보고를 통해
                  아동을 위한 기업 활동이 다양한 이해관계자들과 공유되고, 이를
                  통해 다음 활동 계획을 수립할 수 있도록 합니다.
                </li>
                <li>
                  <strong>아동친화적 구제 : </strong>기업으로 인해 권리를
                  침해받은 아동의 회복을 위해 신속하고 정확하게 구제 조치를
                  마련합니다.
                </li>
              </ul>
            </div>
            <div className="col-img">
              <Image pcSrc={BusinessImg} mobileSrc={BusinessImg} />
            </div>
          </div>
        </Container>
      </SectionBusiness>

      <SectionWhy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc t2">
            <h2>
              <Tit size="s1">아동친화기업이 왜 필요한가요?</Tit>
            </h2>
          </SectionHeader>

          <Tit size="s1-5" color="sky" weight="normal">
            어린이는 기업의 미래입니다.
          </Tit>
          <p
            className="dec"
            css={`
              margin-top: 8px;
            `}
          >
            <strong>
              어린이는 여러 기업에게 가장 중요한 이해관계자 집단입니다. 그와
              동시에 어린이는 가장 소외되기 쉬운 집단으로, 아동권리를 존중하기
              위해서는 특별한 관심이 필요합니다.
            </strong>
          </p>

          <ul className="blit-list black">
            <li>
              아동기는 급속도로 성장이 이루어지는 특별한 시기로, 이 시기에 받은
              영향은 어린이의 신체적, 심리적, 정서적 건강을 평생 좌우합니다.
              건강하게 성장할 수 있도록 어린이는 성장기에 영양가 있는 음식과
              깨끗한 물을 섭취하고, 적절한 보호와 사랑을 받아야 합니다.
            </li>
            <li>
              어린이는 성인에 비해 일상 속 위험에 의해 피해입을 가능성이
              높습니다. 성인은 충분히 견뎌낼 수 있는 경제적·사회적·신체적 충격도
              어린이에게는 일생일대의 사건으로 남을 수도 있습니다.
            </li>
            <li>
              기업에 고용된 어린이나 기업의 영향을 받는 어린이가 누구이고, 몇
              명인지 확인하는 일은 쉽지 않습니다. 기업의 복잡한 공급망
              어딘가에서 불법 고용되어 일하는 어린이, 기업의 보안서비스에 의해
              체포되거나 구금된 어린이 등 보이지 않는 곳에서 고통받는 어린이들이
              아직도 많습니다.
            </li>
            <li>
              어린이가 공식적인 의견을 낼 수 있는 통로가 부족합니다.
              어린이에게는 노동조합에 투표할 기회도, 기업의 주식을 보유해
              주주총회에 참석할 기회도 흔치 않습니다.
            </li>
            <li>
              어린이의 의견이 수렴되는 경우도 거의 없습니다. 기업의 경영계획이
              아동권리에 악영향을 주지 않도록 하기 위해서 기업은 이해관계자와
              협의과정에서 아동권리도 반드시 고려해야 하며, 필요할 경우 어린이를
              직접 만나 의견을 들어야 합니다.
            </li>
            <li>
              일터에서 아동권리는 단순한 노사문제 이상입니다. 기업은 모든
              경영활동(제품과 서비스, 마케팅 방식, 지방정부 또는 중앙정부와의
              관계, 지역사회를 위한 전략적 투자)이 어린이에게 미치는 직·간접적
              영향을 이해해야 합니다.
            </li>
          </ul>
        </Container>
      </SectionWhy>

      <SectionWhy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc t2">
            <h2>
              <Tit size="s1">아동친화기업이 어떤 변화를 가져오나요?</Tit>
            </h2>
          </SectionHeader>

          <Tit size="s1-5" color="sky" weight="normal">
            어린이의 행복이 기업의 행복입니다.
          </Tit>
          <p
            className="dec"
            css={`
              margin-top: 8px;
            `}
          >
            <strong>
              기업이 아동권리를 존중하고 지원하는 것은 기업 자체에 큰 이득이
              됩니다.
            </strong>
          </p>

          <ul className="blit-list black">
            <li>
              리스크 관리가 개선될 수 있습니다. 환경 문제나 사회적 문제를
              포함하여 인권 문제, 보건, 안전, 제품의 품질책임, 아동권리 옹호와
              취약성 관리를 아우르는 광범위한 리스크 관리가 가능합니다.
            </li>
            <li>
              기업의 인지도와 사회적 호감도를 높일 수 있습니다. 기업은 사회적
              가치를 고려하여 만든 제품 또는 마케팅, 지역사회와의 우호적인 관계
              형성 등을 통해 어린이와 부모에게 긍정적인 이미지를 전할 수
              있습니다.
            </li>
            <li>
              노동자의 사기를 높일 수 있습니다. 공정한 임금과 양질의 근무조건을
              제공하는 것은 직원에게 동기를 부여하고, 생산성을 높이며, 결근율을
              낮출 수 있습니다.
            </li>
            <li>
              차세대 인재 육성에 도움이 될 수 있습니다. 견습교육 프로그램과 교육
              제도를 지원하여 어린이와 청소년들이 의사결정 능력과 리더십을
              발달시킬 수 있게 합니다.
            </li>
            <li>
              지속가능하고 안정적인 기업 환경 조성에 기여할 수 있습니다.
              아동권리를 존중하는 기업 활동은 건전한 지역사회 그리고 건실한
              경제구조를 조성하는데 긍정적인 영향을 미칩니다.
            </li>
          </ul>
          <div className="btn-wrap">
            <LinkSafe to="/what-we-do/database?keyword=아동친화기업">
              <ButtonMoreView mode="sky">
                아동친화기업 자료 더 보기
              </ButtonMoreView>
            </LinkSafe>
          </div>
        </Container>
      </SectionWhy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A028" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {news.length > 0 && (
            <RelativeBoardCard
              newslist={news}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default ChildrensRightsAndBusiness;
